import $ from 'jquery'
import stage from './components/stage'
import render from './components/render'
import GoogleMapsLoader from 'google-maps'
import gsap from 'gsap'
import Barba from 'barba.js'
import 'jquery.scrollto'
import slick from 'slick-carousel'
import Pikaday from 'pikaday'
GoogleMapsLoader.KEY = 'AIzaSyBpmDhVlHbRq7CCnt_g9Ywk0mZmBeBH6XY';
GoogleMapsLoader.LIBRARIES = ['places'];

class App {

  constructor() {
    this.init()
  }

  init() {

    this.renderQue = this.render.bind(this);
    this.resizeQue = this.resize.bind(this);

    this.addBarba()

    var page = Barba.BaseView.extend({
      namespace: 'page',
      onEnter: function() {
        console.log('page enter');
      },
      onEnterCompleted: this.addEvents.bind(this),
      onLeave: this.removeEvents.bind(this),
      onLeaveCompleted: this.destroy.bind(this)
    });
    page.init()

    Barba.Pjax.start();

  }



  addEvents() {

    this.nav = $('.nav');
    this.logo = $('.logotype');
    this.body = $('body')

    $('.slider').slick({
      prevArrow: $('.prevArrow'),
      nextArrow: $('.nextArrow')
    });

    $('.book-now').on('click', (e)=> {
      e.preventDefault()
      this.body.addClass('hasModal');

    })

    $('.button--close').on('click',  (e) => {
      e.preventDefault();
      this.body.removeClass('hasModal')
    })

    $('form input').on('click',  (e) => {
        console.log($(e.currentTarget)[0]);
        $(e.currentTarget).parent().removeClass('field--invalid')
    })

    $('.modal').on('click',  (e) => {
      //e.preventDefault();
    //  console.log(e.target, $('.modal')[0]);
      if( e.target == $('.modal')[0]){
        this.body.removeClass('hasModal')
        $('.field--invalid').removeClass('field--invalid')
        form[0].reset()
      }
    })

    $('.hamburger').on('click',  (e) => {
      e.preventDefault();
      this.body.toggleClass('hasNav')
      $('.hamburger').toggleClass('is-active')
    })

    var pickers = new Pikaday({ field: document.getElementById('date-start') });
    var pickere = new Pikaday({ field: document.getElementById('date-end') });


    GoogleMapsLoader.load(function(google) {

      var input = document.getElementById('location');
      var autocomplete = new google.maps.places.Autocomplete(input);

    })





    $('.wpcf7-form').on('submit', (e)=>{

        e.preventDefault()
        const form = $('.wpcf7-form')
      //  const url = form.attr('action')
        const form_id = $('[name="_wpcf7"]').attr('value')
        const url = `http://walley.ro/admin/wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`

        $('input.button').addClass('loading')

        this.reservation(url).done(
              (data) => {

                  $('input.button').removeClass('loading')

                  if(data.status == "validation_failed"){
                    $.each(data.invalidFields, ( index, value ) => {

                      var inputname = value.into.replace('span.wpcf7-form-control-wrap.', '')
                      $(`[name="${inputname}"]`).parent().addClass('field--invalid')
                    });

                  }
                  else {
                      form[0].reset()
                      this.body.removeClass('hasModal')
                  }
              }
        )
    })

    stage.on("stage:scroll", this.renderQue);
    stage.on("stage:resize", this.resizeQue);
    this.resize();

  }

  removeEvents() {

    this.body.removeClass('hasNav')
    $('.hamburger').removeClass('is-active')

    stage.removeListener("stage:scroll", this.renderQue)
    stage.removeListener("stage:resize", this.resizeQue)
  }


  resize(e){

    if(!e)
      e = stage

    this.render(e)
  }


  render(e) {

    var h = 0.03472 * e.w
    var s = (1 - (e.scrolltop / h)/9)
  //  console.log(s, h);
    //function(val, max, min) { return (val - min) / (max - min); }

    if(e.scrolltop > h){
    //  TweenMax.set(this.nav, { y:-h, force3D: true})
    TweenMax.set(this.logo, { y:-h , scale:.4, force3D: true})
    }
    else {
    //  TweenMax.set(this.nav, { y:-e.scrolltop, force3D: true})
     TweenMax.set(this.logo, { y:-e.scrolltop, scale:s, force3D: true})
    }
    //console.log(e.scrolltop);
    this.nav.toggleClass('is-sticky', e.scrolltop > h)
  //  this.nav.toggleClass('is-up', e.scrollDirection >= 0 && e.scrolltop > h)

  }

  addBarba(){
    //var thiz = this


    var FadeTransition = Barba.BaseTransition.extend({
        start: function() {
          /**
           * This function is automatically called as soon the Transition starts
           * this.newContainerLoading is a Promise for the loading of the new container
           * (Barba.js also comes with an handy Promise polyfill!)
           */

          // As soon the loading is finished and the old page is faded out, let's fade the new page
          Promise
            .all([this.newContainerLoading, this.fadeOut()])
            .then(this.fadeIn.bind(this));
        },

        fadeOut: function () {
          var deferred = Barba.Utils.deferred();
            TweenLite.to('.barba-container', 1, {
              autoAlpha: 0,
              onComplete: function () {
                deferred.resolve();
              }
            });
          return deferred.promise;
        },

        // fadeOut: function() {
        //   /**
        //    * this.oldContainer is the HTMLElement of the old Container
        //    */
        //
        //   return $(this.oldContainer).animate({ opacity: 0 }).promise();
        // },

        fadeIn: function() {
          /**
           * this.newContainer is the HTMLElement of the new Container
           * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
           * Please note, newContainer is available just after newContainerLoading is resolved!
           */

          var _this = this;
          var $el = $(this.newContainer);

          $(this.oldContainer).hide();
          //
          // $el.css({
          //   visibility : 'visible',
          //   opacity : 0
          // });
          $.scrollTo({top:0, left: 0}, 1)

          TweenLite.to($el, 1, {
            autoAlpha: 1,
            onComplete: function () {
              $el.addClass('container-loaded')
              _this.done();
            }
          });

          // $el.animate({ opacity: 1 }, 400, function() {
            /**
             * Do not forget to call .done() as soon your transition is finished!
             * .done() will automatically remove from the DOM the old Container
             */

            // _this.done();
          // });
        }
      });

      /**
       * Next step, you have to tell Barba to use the new Transition
       */

      Barba.Pjax.getTransition = function() {
        /**
         * Here you can use your own logic!
         * For example you can use different Transition based on the current page or link...
         */

        return FadeTransition;
      };
  }

  reservation(url) {

    var data = $('.wpcf7-form').serialize()

    var promise = $.ajax({
        url: url ,
        method: 'post',
        data: data
    })

    return promise;
  }

  destroy(){
    // GoogleMapsLoader.release(function() {
    //   //console.log('No google maps api around');
    // });
  }

}

module.exports = App
