import App from './app'
// import $ from 'jquery'
const app = new App()
//
// $(window).on('load', function() {
//
//     setTimeout(() => {
//       const app = new App()
//       $('body').addClass('loaded');
//
//   }, 300)
//
// });
